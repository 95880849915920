
function remover($target){
	$($target).fadeOut('fast',function(){
		$($target).remove();
	})
}

$('#toggleMenu, .bg-menu').click(function () {
	$('.nav-bar').toggleClass('active');

})